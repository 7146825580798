/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { commonInstance, parseResult } from './index'

import type { Result } from './index'

export interface TakeTurns {
  id?: string,
  content: string,
  file: string,
  name: string
}

export async function getTakeTurnsList () {
  return parseResult(await commonInstance.get<Result<TakeTurns[]>>('/take/list', { disableCache: true }), [])
}

export async function createTake (t: TakeTurns): Promise<boolean> {
  return parseResult(
    await commonInstance.post<Result<boolean>>('/take', t, {
      headers: {
        'Content-Type': 'application/json'
      }
    }),
    false
  )
}

export async function updateTake (t: TakeTurns): Promise<boolean> {
  return parseResult(
    await commonInstance.put<Result<boolean>>('/take', t, {
      headers: {
        'Content-Type': 'application/json'
      }
    }),
    false
  )
}

export async function deleteTake (t: TakeTurns[]): Promise<boolean> {
  return parseResult(
    await commonInstance.post<Result<boolean>>('/take/delete/all', t, {
      headers: {
        'Content-Type': 'application/json'
      }
    }),
    false
  )
}
