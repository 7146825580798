/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { commonInstance, parseResult } from './index'

import type { Result } from './index'

export interface Video {
  id?: string,
  videoName: string,
  details: string,
  primaryTitle: string,
  author: string,
  secondaryTitle: string,
  imgFile: string,
  videoFile: string
}

export async function getVideoList () {
  return parseResult(await commonInstance.get<Result<Video[]>>('/video', { disableCache: true }), [])
}

export async function createVideo (v: Video): Promise<boolean> {
  return parseResult(
    await commonInstance.post<Result<boolean>>('/video', v, {
      headers: {
        'Content-Type': 'application/json'
      }
    }),
    false
  )
}

export async function updateVideo (v: Video): Promise<boolean> {
  return parseResult(
    await commonInstance.put<Result<boolean>>('/video', v, {
      headers: {
        'Content-Type': 'application/json'
      }
    }),
    false
  )
}

export async function deleteVideo (v: Video[]): Promise<boolean> {
  return parseResult(
    await commonInstance.post<Result<boolean>>('/video/delete/all', v, {
      headers: {
        'Content-Type': 'application/json'
      }
    }),
    false
  )
}
