/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { commonInstance, parseResult } from './index'

import type { Result } from './index'

export interface Calendar {
  id?: string,
  date: string,
  content: string
}

export async function getCalender () {
  return parseResult(await commonInstance.get<Result<Calendar[]>>('/calendar', { disableCache: true }), [])
}

export async function createCalendar (cal: Calendar): Promise<boolean> {
  return parseResult(
    await commonInstance.post<Result<boolean>>('/calendar', cal, {
      headers: {
        'Content-Type': 'application/json'
      }
    }),
    false
  )
}

export async function updateCalendar (cal: Calendar): Promise<boolean> {
  return parseResult(
    await commonInstance.put<Result<boolean>>('/calendar', cal, {
      headers: {
        'Content-Type': 'application/json'
      }
    }),
    false
  )
}
